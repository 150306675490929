import "bootstrap-dialog";
import Cookies from "js-cookie";
import Input from "src/admin-modules/input.adm";
import Component from "can/component/";
import "can/view/stache/";
import ViewModel from "./login.vm";


Component.extend({
    tag: "hos-login",
    template: can.view("src/components/login/login.template.stache"),
    viewModel: ViewModel,
    events: {
        inserted( $el ) {
            const $body = $el.parent().parent();

            Input.init( $body ).activate();
            Cookies.remove("token", {
                path: "/",
                secure: true,
                sameSite: "None",
            });

            Cookies.remove("JSESSIONID", {
                path: "/",
                secure: true,
                sameSite: "None",
            });

            Cookies.remove("RefreshRate", {
                path: "/",
                secure: true,
                sameSite: "None",
            });

            Cookies.remove("credentials", {
                path: "/",
                secure: true,
                sameSite: "None",
            });

            Cookies.remove("VT-On-Behalf-Of", {
                path: "/",
                secure: true,
                sameSite: "None",
            });

            if (Cookies.get("unauthorized")) {
                Cookies.remove("unauthorized", {
                    path: "/",
                    secure: true,
                    sameSite: "None",
                });
            }
        },
        ".select-language click": function( $el ){
            const selectedLang = $($el).attr("code");

            Cookies.set("preferredLanguage", selectedLang, {
                path: "/",
                secure: true,
                sameSite: "None",
            });
            this.viewModel.attr("lang", selectedLang );
        }
    }
});
