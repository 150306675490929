export default {
    init: function ( $element ) {
        "use strict";
        var _this = this;

        _this.$body = $element;
        _this.$formControl = $element.find( ".form-control" );

        return _this;
    },

    activate: function () {
        "use strict";
        var _this = this;

        //On focus event
        _this
            .$formControl
            .focus( function () {
                $( this )
                    .parent()
                    .addClass( "focused" );
            } );

        //On focusout event
        _this
            .$formControl
            .focusout( function () {
                var $this = $( this );
                if ( $this.parents( ".form-group" ).hasClass( "form-float" ) ) {
                    if ( $this.val() === "" ) {
                        $this.parents( ".form-line" )
                            .removeClass( "focused" );
                    }
                } else {
                    $this.parents( ".form-line" )
                        .removeClass( "focused" );
                }
            } );

        //On label click
        _this.$body
            .on(
                "click", ".form-float .form-line .form-label",
                function () {
                    $( this )
                        .parent()
                        .find( "input" )
                        .focus();
                }
            );
    }
};
